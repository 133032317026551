import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ExampleBarChart from './BarChart';
import BarChartStacked from './BarChartStacked';
import GridByChain from './GridByChain';
// import { AgGridReact } from 'ag-grid-react'; 
import BasicTabs from './BasicTabs';
import { Typography } from '@mui/material';

import { API } from 'aws-amplify';
import { getSummaryData } from '../graphql/permanentQueries';
import { useState, useEffect} from "react";
import { IntegrationInstructionsRounded } from '@mui/icons-material';

const TS = () => {

    const [data, setData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [projectDataByDate, setProjectDataByDate] = useState([]);
    const [versionsAsOf, setVersionsAsOf] = useState([]);
    const [legacyData, setLegacyData] = useState([]);
    const [lockCount, setLockCount] = useState([]);

    useEffect(() => {
        main();
        // console.log("*** QUERY RESULTS = " + JSON.stringify(performQuery()));
        // fetchData();
    }, []);

    function generateOutputMap() {
        var output = new Map();
        output.set('REMAINING_EXPIRED', { DAYS: 'Lock Expired' });
        output.set('REMAINING_0_30', { DAYS: '0-30' });
        output.set('REMAINING_31_90', { DAYS: '31-90' });
        output.set('REMAINING_91_180', { DAYS: '91-180' });
        output.set('REMAINING_181_365', { DAYS: '181-365' });
        output.set('REMAINING_366_730', { DAYS: '366-730' });
        output.set('REMAINING_731_1095', { DAYS: '731-1095' });
        output.set('REMAINING_1096_1460', { DAYS: '1096-1460' });
        output.set('REMAINING_1461_1825', { DAYS: '1461-1825' });
        output.set('REMAINING_TOTAL', { DAYS: 'TOTAL' });

        return output;
    }

    function generateLegacyOutputMap() {
        var output = new Map();
        output.set('TOKEN_BALANCE', { DAYS: 'SWAP Staked' });

        return output;
    }

    function generateByDateMap() {
        var output = new Map();
        output.set('Lock Expired', { DAYS: 'Lock Expired' });
        output.set('0-30', { DAYS: '0-30' });
        output.set('31-90', { DAYS: '31-90' });
        output.set('91-180', { DAYS: '91-180' });
        output.set('181-365', { DAYS: '181-365' });
        output.set('366-730', { DAYS: '366-730' });
        output.set('731-1095', { DAYS: '731-1095' });
        output.set('1096-1460', { DAYS: '1096-1460' });
        output.set('1461-1825', { DAYS: '1461-1825' });
        output.set('TOTAL', { DAYS: 'TOTAL' });

        return output;
    }

    async function main() {
        var currGenDGs = await performQuery(null, "LTSP_SUMMARY_BY_CHAIN");
        var currentVersion = currGenDGs[0].version; // get current version from the first data group (assumes all data groups have same version)

        var combinedDGs = currGenDGs;

        // currentVersion = 10; 

        if(currentVersion - 7 >= 0){
            var currGenM7DGs = await performQuery(currentVersion - 7, "LTSP_SUMMARY_BY_CHAIN");
            if(currGenM7DGs !== undefined){
                combinedDGs = combinedDGs.concat(currGenM7DGs);
            }
            
        }

        if(currentVersion - 14 >= 0){
            var currGenM14DGs = await performQuery(currentVersion - 14, "LTSP_SUMMARY_BY_CHAIN");
            if(currGenM14DGs !== undefined){
                combinedDGs = combinedDGs.concat(currGenM14DGs);
            }
            
        }
        
        console.log("*** QUERY RESULTS = " + JSON.stringify(combinedDGs));

        processData(combinedDGs);

        processLTSPLockCount();

        processLegacyData();
    }

    async function processLTSPLockCount(){
        var currGenDGs = await performQuery(null, "LTSP_LOCK_COUNT_BY_CHAIN");
        //call process Data or getOutputAndVersions

        var outputAndVersions = getOutputAndVersions(currGenDGs, true);
        var output = outputAndVersions.output;
        var dataVersions = outputAndVersions.versions;
        // var versionsAsOf = outputAndVersions.versionsAsOf; 

        console.log("DATA VERSIONS = " + dataVersions);

        console.log(output);

        var barChartAndGridData = [];
        if (dataVersions.length > 0) {
            barChartAndGridData = Array.from(output[dataVersions[0]].values()); //use most recent data for stack bar char and grid
        }

        setLockCount([barChartAndGridData]);
    }

    async function processLegacyData(){
        var currGenDGs = await performQuery(null, "LEGACY_SUMMARY_BY_CHAIN");
        
        var outputAndVersions = getOutputAndVersions(currGenDGs);
        var output = outputAndVersions.output;
        var dataVersions = outputAndVersions.versions;

        var gridData = [];
        if (dataVersions.length > 0) {
            gridData = Array.from(output[dataVersions[0]].values()); //use most recent data 
        }

        console.log('processLegacyData output = ' + JSON.stringify(gridData));

        setLegacyData([gridData,null]);
    }

    function getOutputAndVersions(combinedDGs, isLTSP){
        // create output array that can hold multiple versions / days of data
        var output = [];

        var versionsAsOf = {};
        for (var dataGroup of combinedDGs) {
            // var dataGroup = dataGroups[0];
            var dataItems = dataGroup.dataItems.items;
            for (var item of dataItems) {
                // console.log(item.dataKey + ' = ' + output.get(item.dataKey));
                if (output[item.version] === undefined) { // if the first time we're seeing this version number
                    output[item.version] = (isLTSP ? generateOutputMap() : generateLegacyOutputMap());
                }

                output[item.version].get(item.dataKey)[dataGroup.chain] = item.dataValue;
                versionsAsOf[item.version] = item.asOf;
            }
        }
        console.log(JSON.stringify(versionsAsOf));

        console.log("KEYS = " + Object.keys(output));
        var dataVersions = (Object.keys(output)).sort(function (a, b) { return parseInt(a) - parseInt(b) }).reverse(); //most recent versions first

        return {"output":output, "versions":dataVersions, "versionsAsOf":versionsAsOf};

    }

    function processData(combinedDGs){
        /*********** Process the data  ************/

        // create output array that can hold multiple versions / days of data
        var outputAndVersions = getOutputAndVersions(combinedDGs, true);
        var output = outputAndVersions.output;
        var dataVersions = outputAndVersions.versions;
        var versionsAsOf = outputAndVersions.versionsAsOf; 

        console.log("DATA VERSIONS = " + dataVersions);

        console.log(output);

        var barChartAndGridData = [];
        if (dataVersions.length > 0) {
            barChartAndGridData = Array.from(output[dataVersions[0]].values()); //use most recent data for stack bar char and grid
        }

        // console.log('chart input = ' + JSON.stringify(barChartAndGridData));

        // console.log(project);
        // console.log(dataGroups);

        var projectDataAsOf = versionsAsOf[dataVersions[0]];
        setProjectData([barChartAndGridData, projectDataAsOf]);

        ///////////////////////////////

        //we want the COMBINED values for each of the last 3 versions
        //use dates instead of chains for a new output map

        var byDateMap = generateByDateMap();

        // console.log('values = ' + output[dataVersions[0]].values());

        var byDateAsOf = [];
        for (let i = 0; i < 3 && i < dataVersions.length; i++) { // either 3 versions or less if not that many
            var versionData = Array.from(output[dataVersions[i]].values());
            byDateAsOf[i] = versionsAsOf[dataVersions[i]];
            for (var elem of versionData) {
                byDateMap.get(elem['DAYS'])['' + i] = elem['COMBINED'];
            }
        }

        console.log('for by Date = ' + JSON.stringify(Array.from(byDateMap.values())));
        var dataByDate = Array.from(byDateMap.values());
        setProjectDataByDate([dataByDate, byDateAsOf]);
    }

    const performQuery = (version, groupType) => {
        return (async (version, groupType) => {
            try {
                console.log("*** VERSION = " + version);
                return await API.graphql({ 
                    query: getSummaryData, 
                    variables: { 
                        name: "TrustSwap", 
                        type: groupType, //"LTSP_SUMMARY_BY_CHAIN",
                        version: version
                    } 
                })
                .then((json) => {
                    var project = json.data.listProjects.items[0];
                    var dataGroups = project.dataGroups.items;

                    // console.log("** performQuery = " + JSON.stringify(dataGroups));

                    return dataGroups;
                })
            } catch (error) {
                console.error(error);
            }
        })(version, groupType)
    }

    // const fetchData = () => {
    //     (async () => {
    //         try {
    //             await API.graphql({ query: getSummaryData, variables: { name:"TrustSwap", type: "LTSP_SUMMARY_BY_CHAIN"} })
    //             // .then(response => response.json())
    //             .then((json) => {
    //                 var project = json.data.listProjects.items[0];
    //                 var dataGroups = project.dataGroups.items;

    //                 // create output array that can hold multiple versions / days of data
    //                 var output = []; //[generateOutputMap(), generateOutputMap(), generateOutputMap()];

    //                 // var output = generateOutputMap();
                    
    //                 var versionsAsOf = {};
    //                 for(var dataGroup of dataGroups){
    //                     // var dataGroup = dataGroups[0];
    //                     var dataItems = dataGroup.dataItems.items;
    //                     for(var item of dataItems){
    //                         // console.log(item.dataKey + ' = ' + output.get(item.dataKey));
    //                         if(output[item.version] === undefined){ // if the first time we're seeing this version number
    //                             output[item.version] = generateOutputMap();
    //                         }
                            
    //                         output[item.version].get(item.dataKey)[dataGroup.chain] = item.dataValue;
    //                         versionsAsOf[item.version] = item.asOf;
    //                     }
    //                 }
    //                 console.log(JSON.stringify(versionsAsOf));

    //                 console.log("KEYS = " + Object.keys(output));
    //                 var dataVersions = (Object.keys(output)).sort(function(a,b){return parseInt(a) - parseInt(b)}).reverse(); //most recent versions first

    //                 console.log("DATA VERSIONS = " + dataVersions);
    //                 //Calculate total for each date range?

    //                 console.log(output);

    //                 var barChartAndGridData = [];
    //                 if(dataVersions.length > 0){
    //                     barChartAndGridData = Array.from(output[dataVersions[0]].values()); //use most recent data for stack bar char and grid
    //                 }

    //                 console.log('chart input = ' + JSON.stringify(barChartAndGridData));

    //                 console.log(project);
    //                 console.log(dataGroups);

    //                 var projectDataAsOf = versionsAsOf[dataVersions[0]];
    //                 setProjectData([barChartAndGridData, projectDataAsOf]);

    //                 ///////////////////////////////

    //                 //we want the COMBINED values for each of the last 3 versions
    //                 //use dates instead of chains for a new output map

    //                 var byDateMap = generateByDateMap();

    //                 // console.log('values = ' + output[dataVersions[0]].values());

    //                 var byDateAsOf = [];
    //                 for (let i = 0; i < 3 && i < dataVersions.length; i++) { // either 3 versions or less if not that many
    //                     var versionData = Array.from(output[dataVersions[i]].values());
    //                     byDateAsOf[i] = versionsAsOf[dataVersions[i]];
    //                     for (var elem of versionData) {
    //                         byDateMap.get(elem['DAYS'])['' + i] = elem['COMBINED'];
    //                     }
    //                 }
    //                 // var interval = 5
    //                 // for (let i = 0; i <= 14 && i < dataVersions.length; i++) { // either 3 versions or less if not that many
    //                 //     if(i % interval === 0){
    //                 //         var versionData = Array.from(output[dataVersions[i]].values());
    //                 //         byDateAsOf[i/interval] = versionsAsOf[dataVersions[i]];
    //                 //         for (var elem of versionData) {
    //                 //             byDateMap.get(elem['DAYS'])['' + i/interval] = elem['COMBINED'];
    //                 //         }
    //                 //     }
    //                 // }

    //                 console.log('for by Date = ' + JSON.stringify(Array.from(byDateMap.values())));
    //                 var dataByDate = Array.from(byDateMap.values());
    //                 setProjectDataByDate([ dataByDate, byDateAsOf ]);
    //             })
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     })()
    // }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} justifyContent="">
                <Grid item xs={12} md={12}>
                    <Typography
                            variant="h7"
                            wrap
                            component="a"
                            href="/"
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            TrustSwap Staking Pools
                    </Typography>
                    {/* <Typography
                            variant="h8"
                            wrap
                            component="a"
                            href="/"
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: '"Goudy Bookletter 1911", sans-serif',
                            fontWeight: 400,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            Long Term Staking Pool
                    </Typography> */}
                        
                </Grid>
                {/* <Grid item xs={'auto'}>
                    <Typography
                            variant="h8"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: '"Goudy Bookletter 1911", sans-serif',
                            fontWeight: 400,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            verticalAlign: 'bottom'
                            }}
                        >
                            as of 7/22/22
                    </Typography>
                </Grid> */}
                <Grid item xs={12} md={8}>
                    
                    <BasicTabs data={barChartData} projectData={projectData} projectDataByDate={projectDataByDate} legacyData={legacyData} lockCount={lockCount} />
                </Grid>
                {/* <Grid item xs={8}>
                    <BarChartStacked />
                </Grid>
                <Grid item xs={8}>
                    <GridByChain />
                </Grid> */}

            </Grid>
            {/* <div>
                Data goes here <br/>
                {data.map((item) => (
                    <span>{item.title}<br/></span> 
                ))}
            </div> */}
        </Box>
    )
  }
  
export default TS 