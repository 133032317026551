import React, { Component } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const formatNumber = (input) => {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works
  var number = (typeof input === 'object') ? input.value : input;
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

function renderer(params) {
  return {
    title: params.title,
    content: params.xValue + ': ' + formatNumber(params.yValue),
  };
}

export default class BarChartStacked extends Component {
    // data = [
    //     {
    //       days: "Lock Expired",
    //       ethereum: 47374,
    //       binance: 0,
    //       polygon: 0,
    //       avalanche: 0,
    //     },
    //     {
    //       days: "0-30",
    //       ethereum: 892038,
    //       binance: 29021,
    //       polygon: 39018,
    //       avalanche: 0,
    //     },
    //     {
    //       days: "31-90",
    //       ethereum: 283158,
    //       binance: 4508,
    //       polygon: 28363,
    //       avalanche: 0,
    //     },
    //     {
    //       days: "91-180",
    //       ethereum: 303897,
    //       binance: 26011,
    //       polygon: 36423,
    //       avalanche: 21080,
    //     },
    //     {
    //       days: "181-365",
    //       ethereum: 299459,
    //       binance: 17696,
    //       polygon: 163296,
    //       avalanche: 4193,
    //     },
    //     {
    //       days: "366-730",
    //       ethereum: 724712,
    //       binance: 100026,
    //       polygon: 113721,
    //       avalanche: 6518,
    //     },
    //     {
    //       days: "731-1095",
    //       ethereum: 545367,
    //       binance: 92003,
    //       polygon: 132641,
    //       avalanche: 2025,
    //     },
    //     {
    //       days: "1096-1460",
    //       ethereum: 134121,
    //       binance: 21606,
    //       polygon: 43155,
    //       avalanche: 0,
    //     },
    //     {
    //       days: "1461-1825",
    //       ethereum: 5637880,
    //       binance: 612070,
    //       polygon: 1255319,
    //       avalanche: 110774,  
    //     },
    //   ];

      constructor(props) {
        super(props);

        console.log('in BarChartStacked data = ' + JSON.stringify(props.data));
        var chartData = [...props.data[0]]; // make a deep copy because we are about to remove a value
        if(chartData.length === 10){ // currently this check is required because of double execution bug
          chartData.pop() // remove last row with TOTAL values, which we don't want on this chart
        }
        //chartData.pop(); 
        this.state = {
          options: {
            // height:600,
            // width:1000,
            autoSize:true,
            title: {
              text: "LTSP SWAP Staked",
            },
            subtitle: {
              text: 'by chain as of ' + props.data[1], //7/29/22',
            },
            data: chartData, //this.data,
            series: [
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: 'ETH',
                yName: 'Ethereum',
                stacked: true,
                tooltip: { renderer: renderer }
              },
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: 'MATIC',
                yName: 'Polygon',
                stacked: true,
                tooltip: { renderer: renderer }
              },
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: 'BNB',
                yName: 'Binance',
                stacked: true,
                tooltip: { renderer: renderer }
              },
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: 'AVAX',
                yName: 'Avalanche',
                stacked: true,
                tooltip: { renderer: renderer }
              },
            ],
            axes: [
                {
                  type: 'category',
                  position: 'bottom',
                  title: {
                    text: 'Remaining Days Locked',
                    enabled: true,
                  },
                },
                {
                  type: 'number',
                  position: 'left',
                  title: {
                    text: 'SWAP Staked',
                    enabled: true,
                  },
                  label: {
                    formatter: formatNumber
                  }
                },
            ],
            legend: {
              position: 'top',
            },
            theme: {
              baseTheme: 'ag-material-dark'
            }
          },
        };
      }
    

    render() {
      return (
        <div className="ag-theme-alpine-dark" style={{height:'650px'}}>
            <AgChartsReact options={this.state.options} />
        </div>
      );
    }
}