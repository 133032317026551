import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ExampleBarChart from './BarChart';
import BarChartStacked from './BarChartStacked';
import GridByChain from './GridByChain';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import Item from './StackItem';

import Stats from './Stats';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicTabs({projectData, projectDataByDate, legacyData, lockCount}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('DATA in basic tabs = ' + JSON.stringify({projectDataByDate}));

  return (
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={value} onChange={handleChange} aria-label="staking tabs">
          <Tab label="Long-Term Staking" {...a11yProps(0)} />
          <Tab label="Legacy Staking" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack spacing={2} style={{width:'100%'}}>
          <Item>{projectDataByDate[0] ?  <ExampleBarChart data={projectDataByDate} /> : <>Still loading...</>}</Item>
          <Item>{projectData[0] ?  <BarChartStacked data={projectData} /> : <>Still loading...</>}</Item>
          <Item>{projectData[0] ?  <GridByChain data={projectData} leftCol='Days Remaining' title='LTSP SWAP Staked By Chain' /> : <>Still loading...</>}</Item>
          <Item>{lockCount[0] ?  <GridByChain data={lockCount} leftCol='Days Remaining' title='LTSP Lock Count By Chain' /> : <>Still loading...</>}</Item>
          <Item><Stats /></Item>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stack spacing={2} style={{width:'100%'}}>
          <Item>{legacyData[0] ?  <GridByChain data={legacyData} leftCol='Legacy Staking' /> : <>Still loading...</>}</Item>
        </Stack>
      </TabPanel>
    </Box>
    // <Box sx={{ width: '100%'}}>
    //   <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
    //     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    //       <Tab label="By Date" {...a11yProps(0)} />
    //       <Tab label="By Chain" {...a11yProps(1)} />
    //       <Tab label="Stats" {...a11yProps(2)} />
    //     </Tabs>
    //   </Box>
    //   <TabPanel value={value} index={0}>
    //     {projectDataByDate[0] ?  <ExampleBarChart data={projectDataByDate} /> : <>Still loading...</>}
    //   </TabPanel>
    //   <TabPanel value={value} index={1}>
    //     <Stack spacing={2} style={{width:'100%'}}>
    //       <Item><BarChartStacked data={projectData} /></Item>
    //       <Item><GridByChain data={projectData} /></Item>
    //     </Stack>
    //   </TabPanel>
    //   <TabPanel value={value} index={2}>
    //     <Stats />
    //   </TabPanel>
    // </Box>
  );
}
