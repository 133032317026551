import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { API } from 'aws-amplify';
import { getSummaryData } from '../graphql/permanentQueries';
import { useState, useEffect} from "react";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const Stats = ({ children }) => {

  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
      fetchData();
  }, []);

  const fetchData = () => {
    (async () => {
        try {
            await API.graphql({ 
              query: getSummaryData, 
              variables: { 
                name:"TrustSwap", 
                type: "LTSP_GENERAL_STATS",
                // filter: {
                //   chain: {eq: "COMBINED"}
                // }
              } 
            })
            .then((json) => {
              console.log('stats data = ' + JSON.stringify(json));
              console.log(json);

              var project = json.data.listProjects.items[0];
              var dataGroups = project.dataGroups.items;

              // create output array that can hold multiple versions / days of data
              var output = [];

              var TOTAL_5Y_STAKES_GE_100K = 0;

              for (var dataGroup of dataGroups) {
                var dataItems = dataGroup.dataItems.items;
                if (dataGroup.chain == 'COMBINED') {
                  for (var item of dataItems) {
                    if (dataGroup.version == item.version) {  // we just want the current version (ignore any others)
                      if (item.dataKey == '5Y_STAKES_GE_100K') {
                        TOTAL_5Y_STAKES_GE_100K += item.dataValue;
                      }
                    }
                  }
                }
              }
              console.log('5y 100k stakers = ' + TOTAL_5Y_STAKES_GE_100K);

              output[0] = TOTAL_5Y_STAKES_GE_100K;

              setStatsData([output]);
            })
        } catch (error) {
            console.error(error);
        }
    })()
  }

  return (
    <>
      <Stack spacing={2} style={{width:'100%'}}>
        <Item>
          <div><b>Stakers w/ 100k+ SWAP Staked for 5 Years:</b> {statsData[0]}</div>
        </Item>
        {/* <Item>
          <div>More coming soon!</div>
        </Item> */}
      </Stack>
{/*         
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <ResponsiveAppBar />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <main style={{height:'100%', width:'100%'}}>{children}</main>
                </Container>
            </Grid>
        </Grid>
         */}
      
    </>
  )
}

export default Stats