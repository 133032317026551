import * as React from 'react';
// import logo from './logo.svg';
import './App.css';
// import ExampleChart from './AppChart';
// import ExampleBarChart from './BarChart';
import Layout from './components/Layout';
import TS from './components/TS';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import Test from './components/Test';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });


function App() {
  //turn off console logging
  console.log = function() {};

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
          },
        }),
      [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Layout>
          <TS />
        </Layout>
        {/* <Test /> */}
      </main>
    </ThemeProvider>
    
    // <div className="App">
      
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    //   <ExampleChart />
    //   <ExampleBarChart />
      
    // </div>
    
  );
}

export default App;
