import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
// import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

const formatNumber = (number) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Math.floor(number.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const GridByChain = (props) => {

//  const theData0722 = [
//     {
//       "days": "Lock Expired",
//       "ethereum": 23551,
//       "binance": 0,
//       "polygon": 0,
//       "avalanche": 0,
//       "total": 23551
//     },
//     {
//       "days": "0-30",
//       "ethereum": 915820,
//       "binance": 22730,
//       "polygon": 38018,
//       "avalanche": 0,
//       "total": 976567
//     },
//     {
//       "days": "31-90",
//       "ethereum": 240643,
//       "binance": 3969,
//       "polygon": 17172,
//       "avalanche": 0,
//       "total": 261784
//     },
//     {
//       "days": "91-180",
//       "ethereum": 270685,
//       "binance": 26011,
//       "polygon": 30327,
//       "avalanche": 21080,
//       "total": 348102
//     },
//     {
//       "days": "181-365",
//       "ethereum": 298428,
//       "binance": 13643,
//       "polygon": 146304,
//       "avalanche": 4193,
//       "total": 462568
//     },
//     {
//       "days": "366-730 (1-2 yrs)",
//       "ethereum": 660380,
//       "binance": 96149,
//       "polygon": 95436,
//       "avalanche": 6518,
//       "total": 858483
//     },
//     {
//       "days": "731-7095 (2-3 yrs)",
//       "ethereum": 515080,
//       "binance": 85454,
//       "polygon": 122346,
//       "avalanche": 0,
//       "total": 722879
//     },
//     {
//       "days": "1096-1460 (3-4 yrs)",
//       "ethereum": 134121,
//       "binance": 14226,
//       "polygon": 43155,
//       "avalanche": 0,
//       "total": 191503
//     },
//     {
//       "days": "1461-1825 (4-5 yrs)",
//       "ethereum": 5536104,
//       "binance": 507768,
//       "polygon": 1190195,
//       "avalanche": 108565,
//       "total": 7342631  
//     },
//     {
//         "days": "Total Locked",
//         "ethereum": 8594813,
//         "binance": 769949,
//         "polygon": 1682952,
//         "avalanche": 140356,
//         "total": 11188070  
//     },
//   ];

//   const theData = [
//     {
//       "days": "Lock Expired",
//       "ethereum": 47374,
//       "binance": 0,
//       "polygon": 0,
//       "avalanche": 0,
//       "total": 47374
//     },
//     {
//       "days": "0-30",
//       "ethereum": 892038,
//       "binance": 29021,
//       "polygon": 39018,
//       "avalanche": 0,
//       "total": 960077
//     },
//     {
//       "days": "31-90",
//       "ethereum": 283158,
//       "binance": 4508,
//       "polygon": 28363,
//       "avalanche": 0,
//       "total": 316029
//     },
//     {
//       "days": "91-180",
//       "ethereum": 303897,
//       "binance": 26011,
//       "polygon": 36423,
//       "avalanche": 21080,
//       "total": 387411
//     },
//     {
//       "days": "181-365",
//       "ethereum": 299459,
//       "binance": 17696,
//       "polygon": 163296,
//       "avalanche": 4193,
//       "total": 484644
//     },
//     {
//       "days": "366-730 (1-2 yrs)",
//       "ethereum": 724712,
//       "binance": 100026,
//       "polygon": 113721,
//       "avalanche": 6518,
//       "total": 944977
//     },
//     {
//       "days": "731-1095 (2-3 yrs)",
//       "ethereum": 545367,
//       "binance": 92003,
//       "polygon": 132641,
//       "avalanche": 2025,
//       "total": 772037
//     },
//     {
//       "days": "1096-1460 (3-4 yrs)",
//       "ethereum": 134121,
//       "binance": 21606,
//       "polygon": 43155,
//       "avalanche": 0,
//       "total": 198882
//     },
//     {
//       "days": "1461-1825 (4-5 yrs)",
//       "ethereum": 5637880,
//       "binance": 612070,
//       "polygon": 1255319,
//       "avalanche": 110774,
//       "total": 7616043  
//     },
//     {
//         "days": "Total Locked",
//         "ethereum": 8868007,
//         "binance": 902941,
//         "polygon": 1811936,
//         "avalanche": 144590,
//         "total": 11727475  
//     },
//   ];

  console.log('GridByChain data = ' + JSON.stringify(props.data));

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(props.data[0]); ////useState(theData); // Set rowData to Array of Objects, one Object per Row

  // Each Column Definition results in one Column.
//  const [columnDefs, setColumnDefs] = useState([
//     { headerName: 'Days Remaining', field: 'days', pinned: 'left', width: 175, cellStyle:{textAlign:'left'}  },
//     { headerName: 'Ethereum', field: 'ethereum', valueFormatter: formatNumber, type:'numericColumn' }, 
//     { headerName: 'Polygon', field: 'polygon', valueFormatter: formatNumber, type:'numericColumn' },
//     { headerName: 'Binance', field: 'binance', valueFormatter: formatNumber, type:'numericColumn' },
//     { headerName: 'Avalanche', field: 'avalanche', valueFormatter: formatNumber, type:'numericColumn' },
//     { headerName: 'TOTAL', field: 'total', valueFormatter: formatNumber, type:'numericColumn' }
//   ]);
const [columnDefs, setColumnDefs] = useState([
    { headerName: props.leftCol, field: 'DAYS', pinned: 'left', minWidth: 150, cellStyle:{textAlign:'left'}  },
    { headerName: 'Ethereum', field: 'ETH', valueFormatter: formatNumber, type:'numericColumn' }, 
    { headerName: 'Polygon', field: 'MATIC', valueFormatter: formatNumber, type:'numericColumn' },
    { headerName: 'Binance', field: 'BNB', valueFormatter: formatNumber, type:'numericColumn' },
    { headerName: 'Avalanche', field: 'AVAX', valueFormatter: formatNumber, type:'numericColumn' },
    { headerName: 'TOTAL', field: 'COMBINED', valueFormatter: formatNumber, type:'numericColumn' }
  ]);
 
 // DefaultColDef sets props common to all Columns
 const defaultColDef = useMemo( ()=> ({
    //  sortable: true,
    //  resizable: true,
    //  filter: true,
    //  minWidth: '50px',
    //  maxWidth: '100px'
    // cellStyle:{textAlign:'right'}, 
    minWidth: 115
   }));

 // Example of consuming Grid Event
 const cellClickedListener = useCallback( event => {
   //console.log('cellClicked', event);
 }, []);

 const containerStyle = useMemo(() => ({ width: '100%', height: '100%', }), []);
 const gridStyle = useMemo(() => ({ height: '100%', width: '100%',  }), []);
 const gridTheme = useMemo(() => ({}), []);

 // Example using Grid's API
const buttonListener = useCallback( e => {
   gridRef.current.api.deselectAll();
   gridRef.current.api.sizeColumnsToFit();
 }, []);

const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
}, []);

const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
}, []);

 return (
   <div style={containerStyle}>

     {/* Example using Grid's API */}
     <div style={{textAlign:'center', fontSize:16, fontWeight:'bold'}}>
        {props.title}
     </div>

     <div style={{textAlign:'right'}}>
        <button onClick={onBtnExport}>Export to CSV</button>
     </div>

     {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size
        --- could also try ag-material-dark
     */}
     <div className="ag-theme-alpine-dark" style={gridStyle}>

       <AgGridReact
           ref={gridRef} // Ref for accessing Grid's API

           rowData={rowData} // Row Data for Rows

           columnDefs={columnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties

           animateRows={true} // Optional - set to 'true' to have rows animate when sorted
           rowSelection='multiple' // Options - allows click selection of rows

           onCellClicked={cellClickedListener} // Optional - registering for Grid Event

           domLayout={'autoHeight'}
           onGridReady={onGridReady}
           

           />
        
     </div>
   </div>
 );
};

export default GridByChain;