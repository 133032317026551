import React, { Component } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const formatNumber = (input) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    var number = (typeof input === 'object') ? input.value : input;
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
  
  function renderer(params) {
    return {
      title: params.title,
      content: params.xValue + ': ' + formatNumber(params.yValue),
    };
  }

export default class ExampleBarChart extends Component {
    // data = [
    //     {
    //       days: "Lock Expired",
    //       dt708: 83143,
    //       dt715: 53143,
    //       dt722: 23551,
    //       dt729: 47374
    //     },
    //     {
    //       days: "0-30",
    //       dt708: 844673,
    //       dt715: 967388,
    //       dt722: 976567,
    //       dt729: 960077
    //     },
    //     {
    //       days: "31-90",
    //       dt708: 235645,
    //       dt715: 267720,
    //       dt722: 261784,
    //       dt729: 316029
    //     },
    //     {
    //       days: "91-180",
    //       dt708: 250732,
    //       dt715: 244798,
    //       dt722: 348102,
    //       dt729: 387411
    //     },
    //     {
    //       days: "181-365",
    //       dt708: 371743,
    //       dt715: 416979,
    //       dt722: 462568,
    //       dt729: 484644
    //     },
    //     {
    //       days: "366-730",
    //       dt708: 697466,
    //       dt715: 769450,
    //       dt722: 858483,
    //       dt729: 944977
    //     },
    //     {
    //       days: "731-1095",
    //       dt708: 566989,
    //       dt715: 615096,
    //       dt722: 722879,
    //       dt729: 772037
    //     },
    //     {
    //       days: "1096-1460",
    //       dt708: 174141,
    //       dt715: 183641,
    //       dt722: 191503,
    //       dt729: 198882
    //     },
    //     {
    //         days: "1461-1825",
    //         dt708: 6093964,
    //         dt715: 6717882,
    //         dt722: 7342631,
    //         dt729: 7616043
    //     },
    //   ];

      constructor(props) {
        super(props);

        console.log('in Bar Chart = ' + props.data);
        var series = [
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: '2', //'dt715',
                yName: props.data[1][2], //'7/15/22',
                tooltip: { renderer: renderer }
              },
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: '1', //'dt722',
                yName: props.data[1][1], //'7/22/22',
                tooltip: { renderer: renderer }
              },
              {
                type: 'column',
                xKey: 'DAYS',
                yKey: '0', //'dt729',
                yName: props.data[1][0], //'7/29/22',
                tooltip: { renderer: renderer }
              },
            ];

        var numVersions = props.data[1].length;
        var numLessThanExpected = 3 - numVersions;
        series.splice(0,numLessThanExpected); // remove however many columns we don't have of data

        var chartData = [...props.data[0]]; // make a deep copy because we are about to remove a value
        if(chartData.length === 10){ // currently this check is required because of double execution bug
          chartData.pop(); // remove last row with TOTAL values, which we don't want on this chart
        }
        
    
        this.state = {
          options: {
            // height:600,
            // width:1000,
            autoSize:true,
            title: {
              text: "LTSP SWAP Staked",
            },
            subtitle: {
              text: 'all chains combined',
            },
            data: chartData,
            series: series,
            axes: [
                {
                  type: 'category',
                  position: 'bottom',
                  title: {
                    text: 'Remaining Days Locked',
                    enabled: true,
                  },
                },
                {
                  type: 'number',
                  position: 'left',
                  title: {
                    text: 'SWAP Staked',
                    enabled: true,
                  },
                  label: {
                    formatter: formatNumber
                  }
                },
            ],
            legend: {
                position: 'top',
            },
            theme: {
                baseTheme: 'ag-material-dark'
            }
          },
        };
      }


    render() {
        return (
            <div className="ag-theme-alpine-dark" style={{height:'650px'}}>
                <AgChartsReact options={this.state.options} />
            </div>
        );
    }
}