export const getSummaryData = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $type: String
    $version: Int
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        # dataGroups(type: {eq: "LTSP_SUMMARY_BY_CHAIN"}) {
        dataGroups(type: {eq: $type}) {
          items {
            type
            version
            chain
            dataItems(version: {eq: $version}, sortDirection: DESC) {
              items {
                dataKey
                dataValue
                version
                asOf
              }
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
