// import Navigation from './Navigation'
import ResponsiveAppBar from './ResponsiveAppBar'
import Footer from './Footer'
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

const Layout = ({ children }) => {
  return (
    <>
        
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <ResponsiveAppBar />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl" >
                    <main style={{height:'100%', width:'100%'}}>{children}</main>
                </Container>
            </Grid>
        </Grid>
        
      {/* <Stack spacing={2} style={{width:'100%'}}>
        <ResponsiveAppBar />
        <main style={{height:'100%', width:'100%'}}>{children}</main>
        {/* <Footer />
      </Stack> */}
    </>
  )
}

export default Layout